<template>
  <div class="text mb-10" :class="$props.border ? 'has-border' : ''">
    <Headline v-if="headline" type="h2" :text="headline" :classes="'text-'+ $props.headline_color + ' font-heavy text-left text-large leading-7 mb-5'" />
    <div class="text-content" v-html="content"></div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import filterService from "@/services/filterService";

const Headline = defineAsyncComponent(() => import('@/components/Headline'));

export default {
  name: "Text",
  props: ["headline", "text", "headline_color", "border"],
  components: {
    Headline
  },
  data() {
    return {
      content: ''
    }
  },
  mounted() {
    this.content = filterService.textCompile(this.$props.text, {emoji: true, openLinksInNewWindow: false});
  }
}
</script>

<style scoped lang="scss">
  .has-border {
    padding: 1.25rem;
    border: 4px solid var(--pumpkin-light);
  }
</style>